import React, { useEffect, useRef } from 'react';
import { Link, useStaticQuery, graphql  } from 'gatsby';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Img from 'gatsby-plugin-image';
import Slider from 'react-slick';
import HeroCarousel from "react-hero-carousel";

import wordmark from '../../images/AlecBaldwinWordMark.png';
import * as heroStyles from './hero.module.scss'
import { fadeIn, wordMarkVariants, lineVariants, ulVariants, navItemVariants,ImagesBackground } from './indexVariants';



const Hero = ({ slug, setNavVisible }) => {
   
  const links = [
    {name: 'Home', link: '/'},
    {name: 'Bio', link: '/bio/'},
    {name: 'Current Project', link: '/project/'},
    {name: 'Social media', link: 'https://www.instagram.com/alecbaldwininsta'},
    {name: 'Here\'s the thing', link: '/podcast/'},
    {name: 'HILARIA & ALEC\nBALDWIN FOUNDATION', link: 'https://www.facebook.com/AlecBaldwinFoundation'},
    {name: '30 Rock SNL', link: '/rocks/'},
    {name: 'Advocacy', link: '/advocacy/'},
    {name: 'Gallery', link: '/gallery'}
  ];
  const { ref, inView } = useInView({ threshold: 0.1 });
  //  to be used with the slideshow
  const sliderRef = useRef();
  const images = useStaticQuery(graphql`
    query MyQuery {
      allFile(
        filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "hero_slideshow"}}
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `);
  
  

  useEffect(() => {
    setNavVisible(!inView);
  }, [setNavVisible, inView])

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

 

  return (
    <>   
<div className="container-fluid" >
    <div className="container-fluid">
    <div className="row">

      <div className="col-md-4"><div className={heroStyles.gradient}></div>
      <motion.section ref={ref}  className={heroStyles.hero1}
        variants={fadeIn}
        custom={1}
        initial="initial"
        animate="animate"
      >
          
        <nav className={heroStyles.absolute, heroStyles.heronav}>
          <motion.ul
            variants={ulVariants}
          >
            
             <span className={heroStyles.spantitle} >ALEC <b>BALDWIN</b></span>
 
            {
              links.map((link, index) => {
                const words = link.name.split('\n');
                return (
                  <motion.li
                    key={index}
                    variants={navItemVariants}
                  >
                    <Link to={link.link} className={slug === link.link ? heroStyles.active : undefined}>
                      {
                        words.map((word, index) => (
                          <span key={index}>{word.toUpperCase()}<br/></span>
                        ))
                      }
                    </Link>
                  </motion.li>
                )}
              )
            }
          </motion.ul>
        </nav>
       
          </motion.section></div>
      <div className="col-md-8"> <HeroCarousel interval={3000} >
        <div className={heroStyles.alec_slide_cover_1} ></div>
        <div className={heroStyles.alec_slide_cover_2} ></div>
        <div className={heroStyles.alec_slide_cover_3} ></div>
        <div className={heroStyles.alec_slide_cover_4} ></div>
        <div className={heroStyles.alec_slide_cover_5} ></div>
        <div className={heroStyles.alec_slide_cover_6} ></div>
        <div className={heroStyles.alec_slide_cover_7} ></div>
        <div className={heroStyles.alec_slide_cover_8} ></div>
        <div className={heroStyles.alec_slide_cover_9} ></div>
        <div className={heroStyles.alec_slide_cover_10} ></div>
        <div className={heroStyles.alec_slide_cover_11} ></div>
    
     
  </HeroCarousel>
       </div>

    </div>
    </div>
    </div>
   {/* <div className={heroStyles.gradient}></div>
      <motion.section ref={ref}  className={heroStyles.hero1}
        variants={fadeIn}
        custom={1}
        initial="initial"
        animate="animate"
      >
           <HeroCarousel interval={3000} className={heroStyles.heronav}>
        <div className={heroStyles.alec_slide_cover_1} ></div>
        <div className={heroStyles.alec_slide_cover_2} ></div>
        <div className={heroStyles.alec_slide_cover_3} ></div>
        <div className={heroStyles.alec_slide_cover_4} ></div>
        <div className={heroStyles.alec_slide_cover_5} ></div>
        <div className={heroStyles.alec_slide_cover_6} ></div>
        <div className={heroStyles.alec_slide_cover_7} ></div>
        <div className={heroStyles.alec_slide_cover_8} ></div>
        <div className={heroStyles.alec_slide_cover_9} ></div>
        <div className={heroStyles.alec_slide_cover_10} ></div>
        <div className={heroStyles.alec_slide_cover_11} ></div>
    
     
  </HeroCarousel>
       
        
        <nav className={heroStyles.absolute, heroStyles.heronav}>
          <motion.ul
            variants={ulVariants}
          >
             <div className={heroStyles.wordmarkLogo} >
          <motion.img src={wordmark} alt="Alec Baldwin"
            variants={wordMarkVariants} 
          />
          <motion.span className={heroStyles.separator} 
            variants={lineVariants}
          />
        </div>
            {
              links.map((link, index) => {
                const words = link.name.split('\n');
                return (
                  <motion.li
                    key={index}
                    variants={navItemVariants}
                  >
                    <Link to={link.link} className={slug === link.link ? heroStyles.active : undefined}>
                      {
                        words.map((word, index) => (
                          <span key={index}>{word.toUpperCase()}<br/></span>
                        ))
                      }
                    </Link>
                  </motion.li>
                )}
              )
            }
          </motion.ul>
        </nav>
       
          </motion.section>*/}
    </>
  )
          
}

export default Hero
