// extracted by mini-css-extract-plugin
export var relative = "hero-module--relative--3hmZJ";
export var absolute = "hero-module--absolute--Teqcf";
export var heronav = "hero-module--heronav--162lw";
export var alec_slide_cover_1 = "hero-module--alec_slide_cover_1--1u39Y";
export var alec_slide_cover_2 = "hero-module--alec_slide_cover_2--2LQL2";
export var alec_slide_cover_3 = "hero-module--alec_slide_cover_3--k0tre";
export var alec_slide_cover_4 = "hero-module--alec_slide_cover_4--3_c_Y";
export var alec_slide_cover_5 = "hero-module--alec_slide_cover_5--1XJk3";
export var alec_slide_cover_6 = "hero-module--alec_slide_cover_6--2f2_T";
export var alec_slide_cover_7 = "hero-module--alec_slide_cover_7--2Ty5R";
export var alec_slide_cover_8 = "hero-module--alec_slide_cover_8--3Uktq";
export var alec_slide_cover_9 = "hero-module--alec_slide_cover_9--3SJOB";
export var alec_slide_cover_10 = "hero-module--alec_slide_cover_10--2xV1-";
export var alec_slide_cover_11 = "hero-module--alec_slide_cover_11--PW4l5";
export var alec_slide_cover_13 = "hero-module--alec_slide_cover_13--1tOIi";
export var alec_slide_1 = "hero-module--alec_slide_1--3VBKU";
export var alec_slide_2 = "hero-module--alec_slide_2--2ucTx";
export var alec_slide_3 = "hero-module--alec_slide_3--2j5d3";
export var alec_slide_4 = "hero-module--alec_slide_4--3s4WJ";
export var alec_slide_5 = "hero-module--alec_slide_5--1nEaR";
export var alec_slide_6 = "hero-module--alec_slide_6--3Esnn";
export var alec_slide_7 = "hero-module--alec_slide_7--L3OR0";
export var alec_slide_8 = "hero-module--alec_slide_8--2OXxa";
export var alec_slide_9 = "hero-module--alec_slide_9--1KCxc";
export var alec_slide_10 = "hero-module--alec_slide_10--kvSxn";
export var alec_slide_11 = "hero-module--alec_slide_11--1aUUk";
export var alec_slide_13 = "hero-module--alec_slide_13--1c-jc";
export var spantitle = "hero-module--spantitle--10HQI";
export var hero1 = "hero-module--hero1--1PBFv";
export var sliderContainer = "hero-module--slider-container--11-hj";
export var gradient = "hero-module--gradient--2eLhF";
export var wordmarkLogo = "hero-module--wordmark-logo--F081_";
export var separator = "hero-module--separator--28Cvu";
export var active = "hero-module--active--QLOxl";
export var hero2 = "hero-module--hero2--1ri_I";
export var hero3 = "hero-module--hero3--3TPZG";